// // import { useEffect, useState } from "react";
// // import { gsap } from "gsap";
// // import { ScrollSmoother } from "@/plugins";
// // import debounce from "lodash.debounce";

// // const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

// // const useScrollSmooth = () => {
// //   const [isScrollSmooth, setIsScrollSmooth] = useState(true);

// //   useEffect(() => {
// //     if (isScrollSmooth) {
// //       gsap.config({
// //         nullTargetWarn: false,
// //       });

// //       const smoother = ScrollSmoother.create({
// //         smooth: 0.8, // Increase smoothness
// //         effects: false, // Disable effects for better performance
// //         smoothTouch: 0.1,
// //         normalizeScroll: isMobile() && !document.querySelector("body:not(#uid42)") ? true : false,
// //         ignoreMobileResize: isMobile() && !document.querySelector("#uid1") ? true : false,
// //         onUpdate: debounce(() => {
// //           // Apply will-change: transform to moving elements
// //           document.querySelectorAll('.moving-element').forEach(el => {
// //             (el as HTMLElement).style.willChange = 'transform';
// //           });
// //         }, 100), // Debounce updates
// //       });

// //       return () => {
// //         smoother.kill();
// //       };
// //     }
// //   }, [isScrollSmooth]);

// //   return [isScrollSmooth, setIsScrollSmooth];
// // };

// // export default useScrollSmooth;
// import { useEffect, useState } from "react";
// import { gsap } from "gsap";
// import { ScrollSmoother } from "@/plugins";

// const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

// const useScrollSmooth = () => {
//   const [isScrollSmooth, setIsScrollSmooth] = useState(true);

//   useEffect(() => {
//     if (isScrollSmooth) {
//       gsap.config({
//         nullTargetWarn: false,
//       });

//       const smoother = ScrollSmoother.create({
//         smooth: 0.1, 
//         effects: false, 
//         smoothTouch: 0.5, // Adjust this value for smoother touch scrolling
//         normalizeScroll: true, // Enable normalizeScroll for mobile
//         ignoreMobileResize: true, // Enable ignoreMobileResize for mobile
//       });

//       // Apply will-change: transform to improve performance
//       document.querySelectorAll(".moving-element").forEach((el) => {
//         (el as HTMLElement).style.willChange = "transform";
//       });

//       // Add a touchstart event listener to prevent the default behavior
//       // This can help with scrolling performance on iOS
//       window.addEventListener('touchstart', (event) => {
//         if (event.touches.length > 1) {
//           event.preventDefault();
//         }
//       }, { passive: false });

//       return () => {
//         smoother.kill();
//         window.removeEventListener('touchstart', () => {});
//       };
//     }
//   }, [isScrollSmooth]);

//   return [isScrollSmooth, setIsScrollSmooth];
// };

// export default useScrollSmooth;


// import { useEffect, useState } from "react";
// import { gsap } from "gsap";
// import { ScrollSmoother } from "@/plugins";
// import debounce from "lodash.debounce";

// const isMobile = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

// const useScrollSmooth = () => {
//   const [isScrollSmooth, setIsScrollSmooth] = useState(true);

//   useEffect(() => {
//     if (isScrollSmooth) {
//       gsap.config({
//         nullTargetWarn: false,
//       });

//       const smoother = ScrollSmoother.create({
//         smooth: 1.0, // Increase smoothness
//         effects: false, // Disable additional effects for optimization
//         smoothTouch: 0.3, // Optimized for smoother touch scrolling
//         normalizeScroll: isMobile(), // Always enable for mobile
//         ignoreMobileResize: isMobile(), // Avoid reinitializing smoother on resize
//         onUpdate: debounce(() => {
//           // Apply will-change: transform to moving elements for better performance
//           document.querySelectorAll(".moving-element").forEach((el) => {
//             (el as HTMLElement).style.willChange = "transform";
//           });
//         }, 100), // Debounce updates
//       });

//       // Prevent multi-touch lag on iOS
//       const preventMultiTouch = (event: TouchEvent) => {
//         if (event.touches.length > 1) {
//           event.preventDefault();
//         }
//       };
//       window.addEventListener("touchstart", preventMultiTouch, { passive: false });

//       return () => {
//         smoother.kill();
//         window.removeEventListener("touchstart", preventMultiTouch);
//       };
//     }
//   }, [isScrollSmooth]);

//   return [isScrollSmooth, setIsScrollSmooth];
// };

// export default useScrollSmooth;



// "use client";
// import { gsap } from "gsap";
// import { useState } from "react";
// import { ScrollSmoother} from '@/plugins';
// import { useGSAP } from "@gsap/react";
// export default function useScrollSmooth() {
//   const [isScrollSmooth, setIsScrollSmooth] = useState<boolean>(true);
//   useGSAP(() => {
//     const smoothWrapper = document.getElementById("smooth-wrapper");
//     const smoothContent = document.getElementById("smooth-content");
//     if (smoothWrapper && smoothContent && isScrollSmooth) {
//       gsap.config({
//         nullTargetWarn: false,
//       });
//       ScrollSmoother.create({
//         smooth: 2,
//         effects: true,
//         smoothTouch: 0.1,
//         normalizeScroll: false,
//         ignoreMobileResize: true,
//       });
//     }
//   });
// }



"use client";
import { gsap } from "gsap";
import { useState } from "react";
import { ScrollSmoother } from "@/plugins";
import { useGSAP } from "@gsap/react";

export default function useScrollSmooth() {
  const [isScrollSmooth, setIsScrollSmooth] = useState<boolean>(true);

  useGSAP(() => {
    const smoothWrapper = document.getElementById("smooth-wrapper");
    const smoothContent = document.getElementById("smooth-content");

    if (smoothWrapper && smoothContent && isScrollSmooth) {
      gsap.config({
        nullTargetWarn: false,
      });

      ScrollSmoother.create({
        smooth: 0.1, // Further increase smoothness
        effects: false, // Disable additional effects for optimization
        smoothTouch: 0.3, // Further optimized for smoother touch scrolling
        normalizeScroll: false, // Disable normalizeScroll for better performance
        ignoreMobileResize: true, // Avoid reinitializing smoother on resize
      });
    }
  });

  return [isScrollSmooth, setIsScrollSmooth];
}